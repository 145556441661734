<template>
	<input type="checkbox" :value="value" v-model="proxyChecked" class="form-check-input">
	<span class="checkmark"></span>
</template>

<script setup>
import { computed } from 'vue';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:checked']);

// eslint-disable-next-line no-undef
const props = defineProps({
	checked: {
		type: [Array, Boolean],
		default: false,
	},
	value: {
		type: String,
		default: null,
	},
});

const proxyChecked = computed({
	get() {
		return props.checked;
	},

	set(val) {
		emit('update:checked', val);
	},
});
</script>
<style lang="scss" scoped>
.form-check-input {
	width: 20px;
	height: 20px;
	padding: 6px 4px 5px 5px;
	gap: 0px;
	margin-top: 0;
	opacity: 0px;
	border-radius: 0;
	color: black;
	border: 1px solid rgba(25, 25, 25, 1);
	&:checked {
		background-color: white !important;
		box-shadow: 0 0 0 0.25rem white !important;
	}
}

// .checkmark {
// 	background-color: white;
// 	color: black;
// }
.checkmark {
  position: relative;
  display: block;
  cursor: pointer;
}
.checkmark:before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -20px;
  left: 0;
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 3px;
}

.checkmark:after {
  content: '';
  position: absolute;
  left: -0.8rem;
  top: -0.2rem;
  width: 5px;
  height: 10px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.form-check-input:checked + .checkmark:after {
  opacity: 1;
}

</style>
